<template lang="pug">
  include ../mixins
  +b.recaptcha
    +e.VUE-RECAPTCHA.wrapper(
      ref="recaptcha",
      size="invisible"
      :sitekey="recaptchaKey"
      @verify="onVerify",
    )
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
  },

  props: {
    recaptchaReset: Boolean,
  },

  data() {
    return {
      recaptchaKey: window.recaptchaKey,
      isLoaded: false,
      int: null,
    }
  },

  mounted() {
    this.createRecaptcha()
  },

  methods: {
    createRecaptcha() {
      const hasScript = document.getElementById('recaptchaScript')

      if (hasScript) {
        this.isLoaded = true

        return
      }

      const script = document.createElement('script')

      script.setAttribute('async', '')
      script.setAttribute('defer', '')

      script.id = 'recaptchaScript'
      script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'

      script.onload = () => {
        this.isLoaded = true
      }

      document.getElementsByTagName('head')[0].appendChild(script)
    },

    reset() {
      this.$refs.recaptcha.reset()
    },

    onVerify(response) {
      this.$emit('verify', response)
    },

    execute() {
      if (this.isLoaded) {
        this.$refs.recaptcha.execute()

        clearTimeout(this.int)
      } else {
        clearTimeout(this.int)

        this.int = setInterval(() => {
          this.execute()
        }, 500)
      }
    },
  },
}
</script>
