<template lang="pug">
  include ../../mixins
  +b.modal.--variant_heading
    +b.close(@click="$emit('close')")
      icon-close
    +e.heading
      +b.P.ds-caption.--size_3md.--size_lg-xl.--weight_semi-bold {{ _("Перевірочний код") }}
    +e.content.--space_2
      validation-observer.relative(
        tag="form"
        ref="observer"
        v-slot="{ errors }"
        @submit.prevent="validateBeforeSubmit()"
      )
        ui-preloader(v-if="isLoad")
        +b.g-row.--appearance_spaced.--space_xl.--space_3xl-xl
          +b.g-cell.g-cols.--12-xs
            d-control-input(
              rules="required"
              type="text"
              v-model="formdata.code"
              name="code"
              :input-label="_('Введіть перевірочний код')"
            )
            +b.ds-panel.--space_lg(v-if="sendInfo.allow")
              +e.element.--offset_top
                +b.P.ds-caption.--size_xs.--size_sm-lg(v-if="resendBlocked") {{ _("Запросити код повторно можна через") }}
                  |&nbsp;
                  b {{ time }}
                  |&nbsp;
                  span {{ _("секунд") }}
                +b.SPAN.ds-link.--size_2xs.--size_sm-lg.--color_blue.--inline(
                  v-else
                  @click.prevent="resendCode"
                ) {{ _("Запросити код повторно") }}
            +b.ds-panel.--space_lg(v-else)
              +e.element.--offset_top
                +b.P.ds-caption.--size_xs.--size_sm-lg {{ _("Ви використали всі спроби на сьогодні. Вкажіть інший номер телефону, або спробуйте завтра") }}
        +b.g-row.--appearance_spaced.--space_2xl
          +b.g-cell.g-cols.--12-xs
            authentication-privacy-policy(
              :text="_('Авторизуючись на сайті Ви погоджуєтеся з')"
            )
          +b.g-cell.g-cols.--12-xs
            +b.BUTTON.control-button(
              @click.prevent="validateBeforeSubmit()"
              :disabled="isLoad"
            )
              +e.element
                +e.SPAN.text {{ _('Підтвердити') }}
          ui-recaptcha(
            ref="recaptcha"
            @verify="onVerify"
          )
          validation-provider(
            slim
            v-slot="{ errors }"
            name="nonFieldErrors"
          )
            +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
              +b.P.error-label {{ errors[0] }}
</template>

<script>
import {
  twoFactorConfirmResource,
  twoFactorRequestConfirmationResource,
} from '@services/2factor.service.js'
import FormMixin from '@mixins/form.mixin.js'
import TimerMixin from '@app/Authentication/TimerMixin'
import { setCookie, getCookie } from '@utils/cookie'
import { getTimeToEndOfDay } from '@utils/utils'

export default {
  mixins: [
    FormMixin,
    TimerMixin,
  ],

  props: {
    info: {},
  },

  data() {
    return {
      isLoad: false,
      formdata: {
        code: '',
      },
      confirmationInfo: {},
      recaptchaInfo: {
        response: null,
      },
      sendInfo: {
        allow: true,
        count: 0,
      },
    }
  },

  async mounted() {
    this.getSendInfo()

    this.isLoad = true

    this.$refs.recaptcha.execute()

    await this.waitRecaptcha()

    this.$refs.recaptcha.reset()

    await this.initConfirmation()

    this.isLoad = false
  },

  methods: {
    async waitRecaptcha() {
      return new Promise(resolve => {
        const unwatch = this.$watch(() => this.recaptchaInfo.response, val => {
          if (val) {
            unwatch()
            resolve(val)
          }
        })
      })
    },

    async onVerify(response) {
      if (response.length) {
        this.$set(this.recaptchaInfo, 'response', response)
      }
    },

    async initConfirmation() {
      // const { diff } = this.getTimeDiff()

      if (!this.sendInfo.allow) return

      await this.getConfirmationCode()

      this.setSendTime()

      this.startTimer()
    },

    setSendTime() {
      setCookie('code_send_at', new Date().getTime())
    },

    getSendInfo() {
      const sentCount = getCookie(this.info.token)
      const prettyCount = sentCount ? Number(sentCount) : 0

      this.sendInfo = {
        allow: prettyCount < 4,
        count: prettyCount,
      }

      if (this.sendInfo.allow) {
        const expiresEndOfDay = getTimeToEndOfDay()

        setCookie(this.info.token, prettyCount + 1, expiresEndOfDay)
      }
    },

    async getConfirmationCode() {
      const formdata = {
        token: this.info.token,
        way: 'default',
        recaptcha: this.recaptchaInfo.response,
      }

      const res = await twoFactorRequestConfirmationResource.execute({}, formdata)

      this.confirmationInfo = res
    },

    async resendCode() {
      this.isResend = true
      this.isLoad = true

      this.$refs.recaptcha.reset()

      setTimeout(() => {
        this.$refs.recaptcha.execute()
      }, 200)

      await this.waitRecaptcha()

      this.$refs.recaptcha.reset()

      this.getSendInfo()

      if (!this.sendInfo.allow) return

      await this.getConfirmationCode()

      this.isLoad = false

      this.notification(this._('Код відправлено'))

      this.setSendTime()

      clearInterval(this.timeInterval)

      this.startTimer()
    },

    send() {
      const formdata = {
        id: this.confirmationInfo.id,
        code: this.formdata.code,
      }

      return twoFactorConfirmResource.execute({}, formdata).then(res => {
        this.$emit('close')

        this.info.callback(res)
      })
    },
  },
}
</script>
